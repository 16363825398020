import { Provider } from '@angular/core';

import { AuthenticationResource, RefreshTokenResourceController } from '@portal/shared/utils';

export function provideController(): Provider {
    return [
        //
        AuthenticationResource,
        RefreshTokenResourceController,
    ];
}
