import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { API_URL_TOKEN } from '../tokens';

export const apiUrlInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const apiUrl: string = inject(API_URL_TOKEN);

    return next(
        req.clone({
            url: `${apiUrl}/${req.url}`,
        })
    );
};
