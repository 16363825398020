import { Provider } from '@angular/core';

import {
    ExternalizeLocalStorage,
    ILocalStorage,
    LOCAL_STORAGE,
    LOCAL_STORAGE_OBSERVER,
    LocalStorage,
    LocalStorageObserver,
} from '@common/utils';

import { ApplicationFeaturesAvailability, ApplicationFeaturesAvailabilityToken } from '@portal/shared/utils';

export function provideLocalStorage(): Provider {
    return [
        LocalStorage,
        ExternalizeLocalStorage,
        {
            provide: LOCAL_STORAGE,
            deps: [ApplicationFeaturesAvailabilityToken, LocalStorage, ExternalizeLocalStorage],
            useFactory: (
                features: ApplicationFeaturesAvailability,
                localStorage: ILocalStorage,
                externalizeLocalStorage: ILocalStorage
            ): ILocalStorage => (features.EXTERNALIZE_SECURITY_SUPPORT ? externalizeLocalStorage : localStorage),
        },
        {
            provide: LOCAL_STORAGE_OBSERVER,
            useClass: LocalStorageObserver,
        },
    ];
}
