export namespace TldUtils {
    export function hasDomain(): boolean {
        return parseHostname(window.location.hostname).domain !== null;
    }

    export function getDomain(): string | null {
        return parseHostname(window.location.hostname).domain;
    }

    export function hasSubdomain(): boolean {
        return parseHostname(window.location.hostname).subdomain !== null;
    }

    export function getSubdomain(): string | null {
        return parseHostname(window.location.hostname).subdomain;
    }

    export function insertSubdomain(subdomain: string, path: string): string {
        const currentUrl = window.location;
        const protocol = currentUrl.protocol; // 'http:' or 'https:'
        const host = currentUrl.host; // 'current.subdomain.example.com'

        // Extract the top-level domain (TLD) and second-level domain (SLD)
        // This assumes the current hostname is in the form of 'subdomain.domain.tld' or 'domain.tld'
        const hostParts = host.split('.');
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const topLevelDomain = hostParts.slice(-2).join('.'); // 'example.com'

        // Now construct the URL with the new subdomain

        return `${protocol}//${subdomain}.${topLevelDomain}/${path}`;
    }
}

/**
 * The simplest domain/subdomain parser
 * If there is a need to improve it and take into account complex real domain names
 * it should be used libraries that includes updates from publicsuffix.org
 * such as psl, parse-domain, tldjs, etc.
 * @param hostname
 */
function parseHostname(hostname: string) {
    const domainParts: string[] = hostname.slice(0).toLowerCase().replace(/\.$/, '').split('.');

    const tld = domainParts.pop() ?? null;
    const sld = domainParts.pop() ?? null;
    const domain = sld && tld ? [sld, tld].join('.') : null;
    const subdomain = domainParts.length ? (domainParts.pop() ?? null) : null;

    return {
        tld: skipWww(tld),
        sld: skipWww(sld),
        domain: skipWww(domain),
        subdomain: skipWww(subdomain),
    };
}

function skipWww(d: string | null): string | null {
    return d !== null && d !== 'www' ? d : null;
}
