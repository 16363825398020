import { Provider } from '@angular/core';

import { Environment } from '../interfaces';
import { API_URL_TOKEN, ENVIRONMENT_TOKEN } from '../tokens';

export function provideEnvironment(environment: Environment): Provider {
    return [
        {
            provide: ENVIRONMENT_TOKEN,
            useValue: environment,
        },
        {
            provide: API_URL_TOKEN,
            useValue: environment.apiUrl,
        },
    ];
}
