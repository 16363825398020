import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { DefaultSnackbarData } from '../../interfaces/snackbar.interface';

@Component({
    standalone: true,
    templateUrl: './snackbar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule],
})
export class SnackbarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: DefaultSnackbarData,
        private snackBarRef: MatSnackBarRef<SnackbarComponent>
    ) {}

    public dismiss() {
        this.snackBarRef.dismiss();
    }
}
