import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, GuardResult, MaybeAsync, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';

import { AUTH_MANAGER, AUTH_NAVIGATOR } from '@portal/shared/utils';

export const notAuthCanActivateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authNavigator = inject(AUTH_NAVIGATOR);
    const authManager = inject(AUTH_MANAGER);
    return authManager.isAuthenticated().pipe(map((isAuthenticated: boolean) => !isAuthenticated || authNavigator.loginUrlTree()));
};

export const notAuthCanMatchGuard: CanMatchFn = (): MaybeAsync<GuardResult> => {
    const authManager = inject(AUTH_MANAGER);
    return authManager.isAuthenticated().pipe(map((isAuthenticated: boolean) => !isAuthenticated));
};
